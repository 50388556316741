var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"citationTracking"},[_c('div',{staticClass:"date-time"},[_c('div',[_c('div',{staticClass:"date-range"},[_vm._v(" 选择时间： "),_c('el-date-picker',{attrs:{"value-format":"yyyy-MM","type":"month","placeholder":"选择起始日期","picker-options":_vm.startTimeOptions},on:{"focus":_vm.clickStartTime,"change":_vm.dataChange},model:{value:(_vm.dateValue[0]),callback:function ($$v) {_vm.$set(_vm.dateValue, 0, $$v)},expression:"dateValue[0]"}}),_vm._v(" ~ "),_c('el-date-picker',{attrs:{"value-format":"yyyy-MM","type":"month","placeholder":"选择结束日期","picker-options":_vm.endTimeOptions},on:{"focus":_vm.clickEndTime,"change":_vm.dataChange},model:{value:(_vm.dateValue[1]),callback:function ($$v) {_vm.$set(_vm.dateValue, 1, $$v)},expression:"dateValue[1]"}})],1),_c('div',{staticClass:"time mt-20"},[_vm._v("数据更新时间："+_vm._s(_vm.time))])]),_c('div',{staticClass:"code-box"},[_c('div',{class:_vm.showCode ? 'code' : ''},[_c('div',{attrs:{"id":"qrcode"}}),(_vm.showCode)?_c('p',{staticClass:"text"},[_vm._v("关注公众号")]):_vm._e(),(_vm.showCode)?_c('p',[_vm._v("接收引证变化通知")]):_vm._e()]),_c('p',{staticClass:"jump-text",on:{"click":_vm.toJumpUrl}},[_vm._v("预览最新引证变化")])])]),_c('div',{staticClass:"part part1"},[_c('h3',[_vm._v("引证概览")]),_c('div',{staticClass:"part1-content"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"content1"},[_c('img',{staticClass:"mag-img",attrs:{"src":_vm.img1,"alt":""}}),_c('div',{staticClass:"numBox numBox1"},[_c('p',[_vm._v(" 本年度累计发表文献"),_c('span',[_vm._v(_vm._s(_vm.yearCitationInfo.magArticleCount || '--'))]),_vm._v("篇 ")])])]),_c('img',{staticClass:"arrow-right",attrs:{"src":_vm.arrowRight,"alt":""}}),_c('div',{staticClass:"content2"},[_c('div',{style:({
              width: '115px',
              height: '115px',
              margin: 'auto'
            }),attrs:{"id":"chartYZWX1"}}),_c('div',{staticClass:"numBox numBox2"},[_c('p',[_vm._v(" 其中"),_c('span',[_vm._v(_vm._s(_vm.yearCitationInfo.count))]),_vm._v("篇被"),_c('span',[_vm._v(_vm._s(_vm.yearCitationInfo.citationsCount || 0))]),_vm._v("篇文献引用 ")])])]),_c('img',{staticClass:"arrow-right",attrs:{"src":_vm.arrowRight,"alt":""}}),_c('div',{staticClass:"content3"},[_c('div',{style:({
              width: '115px',
              height: '115px',
              margin: 'auto'
            }),attrs:{"id":"chartYZWX2"}}),_c('div',{staticClass:"numBox numBox3"},_vm._l((_vm.yearCitationInfo.citationsArticleCountGroupByType),function(item,index){return _c('p',{key:index},[_c('span',{staticClass:"dot",style:({ background: _vm.colorArr[index] })}),_vm._v(_vm._s(item.type)+"："),_c('span',[_vm._v(_vm._s(item.count))]),_vm._v("篇 ")])}),0)]),_c('img',{staticClass:"arrow-right",attrs:{"src":_vm.arrowRight,"alt":""}}),_c('div',{staticClass:"content4"},[_c('div',{style:({
              width: '115px',
              height: '115px',
              margin: 'auto'
            }),attrs:{"id":"chartYZWX3"}}),_c('div',{staticClass:"numBox numBox4"},[_c('p',[_vm._v(" 引证文献发表在学术期刊上的共"),_c('span',[_vm._v(_vm._s(_vm.yearCitationInfo.qkCount || 0))]),_vm._v("篇，涵盖"),_c('span',[_vm._v(_vm._s(_vm.yearCitationInfo.citationsMagCount || 0))]),_vm._v("本期刊 ")])])])])])]),_c('div',{staticClass:"part"},[_vm._m(0),_c('commonTable',{attrs:{"table-data":_vm.tableData,"column-data":_vm.tableColumn,"init-obj":_vm.tablePageObj},on:{"onChangePageData":(pageObj) => {
          _vm.getTableData(pageObj, _vm.GetIssueArticleCitation, 'total')
        }}})],1),_c('div',{staticClass:"part mt-20"},[_c('div',{staticClass:"first-title"},[_vm._v("文献/期刊被引/引证统计")]),_c('div',{staticClass:"flex-content"},[_c('div',{staticClass:"flex-content-item"},[_vm._m(1),_c('commonTable',{attrs:{"max-height":"440","table-data":_vm.BYWXTableData,"column-data":_vm.BYWXTableColumn,"init-obj":_vm.BYWXTablePageObj},on:{"onChangePageData":(pageObj) => {
              _vm.getTableData(pageObj, _vm.GetCountRootArticleCitations, 'BYWX')
            }}})],1),_c('div',{staticClass:"flex-content-item"},[_vm._m(2),_c('commonTable',{attrs:{"max-height":"440","table-data":_vm.YZQKTableData,"column-data":_vm.YZQKTableColumn,"init-obj":_vm.YZQKTablePageObj},on:{"onChangePageData":(pageObj) => {
              _vm.getTableData(pageObj, _vm.GetCountMagCitations, 'YZQK')
            }}})],1)])]),_c('div',{staticClass:"part mt-20"},[_c('div',{staticClass:"first-title"},[_vm._v("机构被引/引证统计")]),_c('div',{staticClass:"flex-content"},[_c('div',{staticClass:"flex-content-item"},[_c('div',{staticClass:"second-title"},[_c('span',{staticClass:"BYJG"},[_vm._v("发文机构 "),_c('el-popover',{attrs:{"placement":"top","trigger":"hover","content":"被引文献的发文机构","popper-class":"BYJG-popover"}},[_c('i',{staticClass:"el-icon-question",attrs:{"slot":"reference"},slot:"reference"})]),_vm._v(" 的被引次数统计")],1)]),_c('commonTable',{attrs:{"max-height":"440","table-data":_vm.BYJGTableData,"column-data":_vm.BYJGTableColumn,"init-obj":_vm.BYJGTablePageObj},on:{"onChangePageData":(pageObj) => {
              _vm.getTableData(pageObj, _vm.GetCountRootMagOrg, 'BYJG')
            }}})],1),_c('div',{staticClass:"flex-content-item"},[_vm._m(3),_c('commonTable',{attrs:{"max-height":"440","table-data":_vm.YZJGTableData,"column-data":_vm.YZJGTableColumn,"init-obj":_vm.YZJGTablePageObj},on:{"onChangePageData":(pageObj) => {
              _vm.getTableData(pageObj, _vm.GetCountCitationsMagOrg, 'YZJG')
            }}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("引证文献详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("文献的被引次数统计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("期刊的引证次数统计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("引证机构的引证次数统计")])])
}]

export { render, staticRenderFns }