<!-- citationTracking -->
<template>
  <div class="citationTracking">
    <div class="date-time">
      <div>
        <div class="date-range">
          选择时间：
          <el-date-picker
            value-format="yyyy-MM"
            v-model="dateValue[0]"
            type="month"
            placeholder="选择起始日期"
            :picker-options="startTimeOptions"
            @focus="clickStartTime"
            @change="dataChange"
          >
          </el-date-picker>
          ~
          <el-date-picker
            value-format="yyyy-MM"
            v-model="dateValue[1]"
            type="month"
            placeholder="选择结束日期"
            :picker-options="endTimeOptions"
            @focus="clickEndTime"
            @change="dataChange"
          >
          </el-date-picker>
        </div>
        <div class="time mt-20">数据更新时间：{{ time }}</div>
      </div>
      <div class="code-box">
        <div :class="showCode ? 'code' : ''">
          <div id="qrcode" />
          <p class="text" v-if="showCode">关注公众号</p>
          <p v-if="showCode">接收引证变化通知</p>
        </div>
        <p class="jump-text" @click="toJumpUrl">预览最新引证变化</p>
      </div>
    </div>
    <div class="part part1">
      <h3>引证概览</h3>
      <div class="part1-content">
        <div class="top">
          <div class="content1">
            <img class="mag-img" :src="img1" alt="" />
            <div class="numBox numBox1">
              <p>
                本年度累计发表文献<span>{{
                  yearCitationInfo.magArticleCount || '--'
                }}</span
                >篇
              </p>
            </div>
          </div>
          <img class="arrow-right" :src="arrowRight" alt="" />
          <div class="content2">
            <div
              id="chartYZWX1"
              :style="{
                width: '115px',
                height: '115px',
                margin: 'auto'
              }"
            />
            <div class="numBox numBox2">
              <p>
                其中<span>{{ yearCitationInfo.count }}</span
                >篇被<span>{{ yearCitationInfo.citationsCount || 0 }}</span
                >篇文献引用
              </p>
            </div>
          </div>
          <img class="arrow-right" :src="arrowRight" alt="" />
          <div class="content3">
            <div
              id="chartYZWX2"
              :style="{
                width: '115px',
                height: '115px',
                margin: 'auto'
              }"
            />
            <div class="numBox numBox3">
              <p
                v-for="(
                  item, index
                ) in yearCitationInfo.citationsArticleCountGroupByType"
                :key="index"
              >
                <span
                  class="dot"
                  :style="{ background: colorArr[index] }"
                ></span
                >{{ item.type }}：<span>{{ item.count }}</span
                >篇
              </p>
            </div>
          </div>
          <img class="arrow-right" :src="arrowRight" alt="" />
          <div class="content4">
            <div
              id="chartYZWX3"
              :style="{
                width: '115px',
                height: '115px',
                margin: 'auto'
              }"
            />
            <div class="numBox numBox4">
              <p>
                引证文献发表在学术期刊上的共<span>{{
                  yearCitationInfo.qkCount || 0
                }}</span
                >篇，涵盖<span>{{
                  yearCitationInfo.citationsMagCount || 0
                }}</span
                >本期刊
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part">
      <div class="second-title"><span>引证文献详情</span></div>
      <commonTable
        :table-data="tableData"
        :column-data="tableColumn"
        :init-obj="tablePageObj"
        @onChangePageData="
          (pageObj) => {
            getTableData(pageObj, GetIssueArticleCitation, 'total')
          }
        "
      />
    </div>
    <div class="part mt-20">
      <div class="first-title">文献/期刊被引/引证统计</div>
      <div class="flex-content">
        <!-- 被引文献 -->
        <div class="flex-content-item">
          <div class="second-title"><span>文献的被引次数统计</span></div>
          <commonTable
            max-height="440"
            :table-data="BYWXTableData"
            :column-data="BYWXTableColumn"
            :init-obj="BYWXTablePageObj"
            @onChangePageData="
              (pageObj) => {
                getTableData(pageObj, GetCountRootArticleCitations, 'BYWX')
              }
            "
          />
        </div>
        <!-- 引证期刊 -->
        <div class="flex-content-item">
          <div class="second-title"><span>期刊的引证次数统计</span></div>
          <commonTable
            max-height="440"
            :table-data="YZQKTableData"
            :column-data="YZQKTableColumn"
            :init-obj="YZQKTablePageObj"
            @onChangePageData="
              (pageObj) => {
                getTableData(pageObj, GetCountMagCitations, 'YZQK')
              }
            "
          />
        </div>
      </div>
    </div>
    <div class="part mt-20">
      <div class="first-title">机构被引/引证统计</div>
      <div class="flex-content">
        <!-- 被引机构 -->
        <div class="flex-content-item">
          <div class="second-title">
            <span class="BYJG"
              >发文机构
              <el-popover
                placement="top"
                trigger="hover"
                content="被引文献的发文机构"
                popper-class="BYJG-popover"
              >
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
              的被引次数统计</span
            >
          </div>
          <commonTable
            max-height="440"
            :table-data="BYJGTableData"
            :column-data="BYJGTableColumn"
            :init-obj="BYJGTablePageObj"
            @onChangePageData="
              (pageObj) => {
                getTableData(pageObj, GetCountRootMagOrg, 'BYJG')
              }
            "
          />
        </div>
        <!-- 引证机构 -->
        <div class="flex-content-item">
          <div class="second-title"><span>引证机构的引证次数统计</span></div>
          <commonTable
            max-height="440"
            :table-data="YZJGTableData"
            :column-data="YZJGTableColumn"
            :init-obj="YZJGTablePageObj"
            @onChangePageData="
              (pageObj) => {
                getTableData(pageObj, GetCountCitationsMagOrg, 'YZJG')
              }
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Get, ProjectConfig, Post } from 'kjqk-commons/src/common/index.js'
import { mapState } from 'vuex'
import commonTable from '@comp/commonTable'
import qrcodejs2 from 'qrcodejs2'
import echarts from 'echarts'
const api = ProjectConfig.emailPush
const CEApi = ProjectConfig.communicationEffectStatistics
export default {
  name: 'CitationTracking',
  data() {
    return {
      GetIssueArticleCitation: api.GetIssueArticleCitation.url,
      GetCountRootArticleCitations: api.GetCountRootArticleCitations.url,
      GetCountMagCitations: api.GetCountMagCitations.url,
      GetCountRootMagOrg: api.GetCountRootMagOrg.url,
      GetCountCitationsMagOrg: api.GetCountCitationsMagOrg.url,
      GetCountRootArticleKeywords: api.GetCountRootArticleKeywords.url,
      GetCountCitatinsArticleKeywords: api.GetCountCitatinsArticleKeywords.url,
      dateValue: [],
      tableData: [],
      tableColumn: [
        {
          title: '刊期',
          prop: 'yearIusse',
          notSortable: true,
          width: '100px'
        },
        {
          title: '被引文献',
          prop: 'rootTitle',
          notSortable: true,
          special: true
        },
        {
          title: '引证文献',
          prop: 'citationsTitle',
          notSortable: true,
          special: true
        },
        {
          title: '引证文献来源分类',
          prop: 'citationsType',
          notSortable: true
        },
        {
          title: '引证文献作者',
          prop: 'citationsAuthor',
          notSortable: true
        },
        {
          title: '引证期刊',
          prop: 'citationsMag',
          notSortable: true
        },
        {
          title: '引证刊期',
          prop: 'citationsYearIusse',
          notSortable: true,
          width: '100px'
        }
      ],
      tablePageObj: {
        page: 1,
        size: 10
      },
      BYWXTableData: [],
      BYWXTableColumn: [
        {
          title: '被引文献',
          prop: 'root_title',
          notSortable: true
        },
        {
          title: '被引次数',
          prop: 'frequency',
          notSortable: true
        }
      ],
      BYWXTablePageObj: {
        page: 1,
        size: 10
      },
      YZQKTableData: [],
      YZQKTableColumn: [
        {
          title: '引证期刊',
          prop: 'CITATIONS_MAG',
          notSortable: true
        },
        {
          title: '引证次数',
          prop: 'frequency',
          notSortable: true
        }
      ],
      YZQKTablePageObj: {
        page: 1,
        size: 10
      },
      BYJGTableData: [],
      BYJGTableColumn: [
        {
          title: '被引机构',
          prop: 'INSTITUTION',
          notSortable: true
        },
        {
          title: '被引次数',
          prop: 'frequency',
          notSortable: true
        }
      ],
      BYJGTablePageObj: {
        page: 1,
        size: 10
      },
      YZJGTableData: [],
      YZJGTableColumn: [
        {
          title: '引证机构',
          prop: 'INSTITUTION',
          notSortable: true
        },
        {
          title: '引证次数',
          prop: 'frequency',
          notSortable: true
        }
      ],
      YZJGTablePageObj: {
        page: 1,
        size: 10
      },
      BYWXGJCTableData: [],
      BYWXGJCTableColumn: [
        {
          title: '被引文献关键词',
          prop: 'keyword',
          notSortable: true
        },
        {
          title: '频次',
          prop: 'count',
          notSortable: true
        }
      ],
      BYWXGJCTablePageObj: {
        page: 1,
        size: 10
      },
      YZWXGJCTableData: [],
      YZWXGJCTableColumn: [
        {
          title: '引证文献关键词',
          prop: 'keyword',
          notSortable: true
        },
        {
          title: '频次',
          prop: 'count',
          notSortable: true
        }
      ],
      YZWXGJCTablePageObj: {
        page: 1,
        size: 10
      },
      showCode: false,
      startTimeOptions: {},
      endTimeOptions: {},
      yearCitationInfo: {},
      colorArr: [
        '#5470C6',
        '#EE6666',
        '#91CC75',
        '#FAC858',
        '#37CBCB',
        '#90C340',
        '#F6B1B4',
        '#FC8452'
      ],
      arrowRight: require('@assets/img/arrow-right.png'),
      img1: require('@assets/img/img1.png'),
      time: ''
    }
  },
  props: {},
  components: { commonTable },
  computed: {
    ...mapState('incrementService', ['magId']),
    ...mapState('micro/user', ['userInfo']),
    todayTimeText() {
      var now = new Date()
      var year = now.getFullYear()
      var month = now.getMonth() + 1
      var date = now.getDate()
      var time = year + '年' + month + '月' + date + '日'
      return time
    }
  },
  mounted() {
    var now = new Date()
    var year = now.getFullYear() // 得到年份
    var month = now.getMonth() // 得到月份
    var todayYearDate = ''
    var todayDate = ''
    if (month + 1 < 6) {
      todayYearDate = year - 1 + '-01'
      todayDate = year - 1 + '-12'
    } else {
      month = month + 1 > 9 ? month + 1 : '0' + (month + 1)
      todayYearDate = year + '-01'
      todayDate = year + '-' + month
    }

    this.dateValue = [todayYearDate, todayDate]
    if (this.magId) {
      this.changeData()
    }
    if (this.userInfo) {
      if (this.userInfo.mobile && this.magId) {
        this.$nextTick(() => {
          this.getCodeUrl()
        })
      }
    }
  },
  watch: {
    magId() {
      this.changeData()
      if (this.userInfo.mobile && this.magId) {
        this.$nextTick(() => {
          this.getCodeUrl()
        })
      }
    },
    userInfo() {
      if (this.userInfo) {
        if (this.userInfo.mobile && this.magId) {
          // this.showCode = true
          this.$nextTick(() => {
            this.getCodeUrl()
          })
        }
      }
    }
  },
  methods: {
    utc2beijing(utc_datetime) {
      // 转为正常的时间格式 年-月-日 时:分:秒
      var T_pos = utc_datetime.indexOf('T')
      var Z_pos = utc_datetime.indexOf('Z')
      var year_month_day = utc_datetime.substr(0, T_pos)
      var hour_minute_second = utc_datetime.substr(T_pos + 1, Z_pos - T_pos - 1)
      var new_datetime = year_month_day + ' ' + hour_minute_second // 2017-03-31 08:02:06
      // 处理成为时间戳
      timestamp = new Date(Date.parse(new_datetime))
      timestamp = timestamp.getTime()
      timestamp = timestamp / 1000
      // 增加8个小时，北京时间比utc时间多八个时区
      var timestamp = timestamp + 8 * 60 * 60
      // 时间戳转为时间
      var beijing_datetime = new Date(parseInt(timestamp) * 1000)
        .toLocaleString()
        .replace(/年|月/g, '-')
        .replace(/日/g, ' ')
      return beijing_datetime // 2017-03-31 16:02:06
    },
    changeData() {
      const params = {
        magId: this.magId
      }
      let url = '/das-api/vas/flow/latestRefreshTime'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.time = data.latestRefreshTime
            ? this.utc2beijing(data.latestRefreshTime)
            : this.todayTimeText
        }
      })
      this.getSumIssueArticleCitationInfo()
      this.getTableData({}, this.GetIssueArticleCitation, 'total')
      this.getTableData({}, this.GetCountRootArticleCitations, 'BYWX')
      this.getTableData({}, this.GetCountMagCitations, 'YZQK')
      this.getTableData({}, this.GetCountRootMagOrg, 'BYJG')
      this.getTableData({}, this.GetCountCitationsMagOrg, 'YZJG')
    },
    getSumIssueArticleCitationInfo() {
      const params = {
        magId: this.magId,
        startTime: this.dateValue[0],
        endTime: this.dateValue[1],
        size: 10
      }
      let url = CEApi.GetSumIssueArticleCitation.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.yearCitationInfo = data
          if (
            data.citationsArticleCountGroupByType &&
            data.citationsArticleCountGroupByType.length > 0
          ) {
            data.citationsArticleCountGroupByType.map((item) => {
              if (item.type == '期刊') {
                this.yearCitationInfo.qkCount = item.count
              }
            })
          } else {
            this.yearCitationInfo.qkCount = 0
          }
          this.chartYZWX1()
          this.chartYZWX2()
          this.chartYZWX3()
        }
      })
    },
    getTableData(pageParams = {}, URL, type) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        startTime:
          this.dateValue && this.dateValue.length > 0 ? this.dateValue[0] : '',
        endTime:
          this.dateValue && this.dateValue.length > 0 ? this.dateValue[1] : '',
        page: page - 1,
        size
      }
      Get(URL, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          if (type === 'total') {
            // 总表
            this.tableData = content
            if (this.tableData.length > 0) {
              this.tableData.map((item) => {
                item.yearIusse =
                  (item.rootYear ? item.rootYear + '年' : '') +
                  (item.rootMagNumber ? item.rootMagNumber + '期' : '')
                item.citationsYearIusse =
                  (item.citationsYear ? item.citationsYear + '年' : '') +
                  (item.citationsNumber ? item.citationsNumber + '期' : '')
              })
            }
            this.tablePageObj = {
              page,
              size,
              total: totalElements
            }
          } else if (type === 'BYWX') {
            // 被引文献
            this.BYWXTableData = content
            this.BYWXTablePageObj = {
              page,
              size,
              total: totalElements
            }
          } else if (type === 'YZQK') {
            // 引证期刊
            this.YZQKTableData = content
            this.YZQKTablePageObj = {
              page,
              size,
              total: totalElements
            }
          } else if (type === 'BYJG') {
            // 被引机构
            this.BYJGTableData = content
            this.BYJGTablePageObj = {
              page,
              size,
              total: totalElements
            }
          } else if (type === 'YZJG') {
            // 引证机构
            this.YZJGTableData = content
            this.YZJGTablePageObj = {
              page,
              size,
              total: totalElements
            }
          }
        }
      })
    },
    chartYZWX1() {
      if (!document.getElementById('chartYZWX1')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYZWX1'))
      myChart.setOption(
        {
          color: ['#73C0DE ', '#E5E8F0'],
          title: {
            text: this.yearCitationInfo.citationsCount + '篇',
            left: 'center',
            top: '50%',
            textStyle: {
              color: '#4054AF',
              fontSize: 16,
              align: 'center',
              fontWeight: 500
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: '引证文献',
              textAlign: 'center',
              fill: '#999999',
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: '引证文献',
              type: 'pie',
              radius: ['100%', '70%'],
              label: {
                show: false,
                position: 'left'
              },
              hoverAnimation: false,
              data: [
                {
                  value: this.yearCitationInfo.citationsCount
                }
              ]
            }
          ]
        },
        true
      )
    },
    chartYZWX2() {
      if (!document.getElementById('chartYZWX2')) {
        return
      }
      var dataArr = this.yearCitationInfo.citationsArticleCountGroupByType
      if (dataArr && dataArr.length > 0) {
        dataArr.map((item) => {
          item.name = item.type
          item.value = item.count
        })
      }
      let _this = this
      const myChart = echarts.init(document.getElementById('chartYZWX2'))
      myChart.setOption(
        {
          color: _this.colorArr,
          title: {
            text: _this.yearCitationInfo.citationsCount + '篇',
            left: 'center',
            top: '50%',
            textStyle: {
              color: '#4054AF',
              fontSize: 16,
              align: 'center',
              fontWeight: 500
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: '引证文献',
              textAlign: 'center',
              fill: '#999999',
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: '引证文献',
              type: 'pie',
              radius: ['100%', '70%'],
              label: {
                show: false,
                position: 'left'
              },
              hoverAnimation: false,
              data: dataArr
            }
          ]
        },
        true
      )
    },
    chartYZWX3() {
      if (!document.getElementById('chartYZWX3')) {
        return
      }
      const myChart = echarts.init(document.getElementById('chartYZWX3'))
      myChart.setOption(
        {
          color: ['#8e8ed7', '#E5E8F0'],
          title: {
            text: this.yearCitationInfo.qkCount || 0 + '篇',
            left: 'center',
            top: '50%',
            textStyle: {
              color: '#4054AF',
              fontSize: 16,
              align: 'center',
              fontWeight: 500
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '40%',
            style: {
              text: '期刊文献',
              textAlign: 'center',
              fill: '#999999',
              fontSize: 14,
              fontWeight: 500
            }
          },
          series: [
            {
              name: '期刊文献',
              type: 'pie',
              radius: ['100%', '70%'],
              label: {
                show: false,
                position: 'left'
              },
              hoverAnimation: false,
              data: [
                {
                  value: this.yearCitationInfo.qkCount || 0
                }
              ]
            }
          ]
        },
        true
      )
    },
    clickStartTime() {
      let endTimeDate = this.dateValue[1]
      this.startTimeOptions = {
        disabledDate: (time) => {
          if (endTimeDate) {
            if (time.getTime() > new Date(endTimeDate).getTime()) {
              return true
            }
            if (time.getTime() > Date.now()) {
              return true
            }
          } else {
            if (time.getTime() > Date.now()) {
              return true
            }
          }
        }
      }
    },
    clickEndTime() {
      let startTimeDate = this.dateValue[0]
      this.endTimeOptions = {
        disabledDate: (time) => {
          if (startTimeDate) {
            if (
              time.getTime() <=
              new Date(startTimeDate).getTime() - 24 * 60 * 60 * 1000
            ) {
              return true
            }
            if (time.getTime() > Date.now()) {
              return true
            }
          } else {
            if (time.getTime() > Date.now()) {
              return true
            }
          }
        }
      }
    },
    dataChange() {
      this.changeData()
    },
    getCodeUrl() {
      let text = ''
      if (this.userInfo.roleList.length > 0) {
        this.userInfo.roleList.map((item, index) => {
          text =
            item.text + (index === this.userInfo.roleList.length - 1 ? '' : ',')
        })
      }
      const params = {
        name: this.userInfo.name, // 非必传
        phone: this.userInfo.mobile, // 必传
        email: this.userInfo.email, // 非必传
        role: text, // 非必传
        magId: this.magId // 必传
      }
      let url = api.getWechatPicAddress.url
      Post(url, params).then((res) => {
        if (res && res.data && typeof res.data == 'string') {
          this.showCode = true
          this.$nextTick(() => {
            document.getElementById('qrcode').innerHTML = ''
            let qrcode = new qrcodejs2('qrcode', {
              width: 120,
              height: 120,
              colorDark: '#000',
              colorLight: '#fff'
            })
            qrcode.makeCode(res.data)
          })
        } else {
          this.showCode = false
          document.getElementById('qrcode').innerHTML = ''
        }
      })
    },
    toJumpUrl() {
      if (!this.magId) return
      let jumpUrl =
        'https://www.das.publish.founderss.cn/otherPages/Microblog/html/docCitedChanges.html?magId=' +
        this.magId
      window.open(jumpUrl, '_blank')
    }
  }
}
</script>
<style lang="scss">
.BYJG-popover {
  padding: 4px 6px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  .popper__arrow {
    display: inline-block;
    border-top-color: rgba(0, 0, 0, 0.8) !important;
  }
}
.citationTracking {
  .date-time {
    .code {
      img {
        margin: auto;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.citationTracking {
  padding: 30px;
  .date-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .code {
      text-align: center;
      color: #999;
      border: 1px solid #999;
      padding: 4px;
      .text {
        margin-top: 10px;
      }
    }
    .jump-text {
      text-align: center;
      margin-top: 10px;
      color: #4054af;
      cursor: pointer;
    }
  }
  .flex-content {
    display: flex;
    justify-content: space-between;
    .flex-content-item {
      width: 49%;
      text-align: center;
    }
  }

  .BYJG {
    span {
      padding: 0;
      display: inline-block;
      position: relative;
      .el-icon-question {
        cursor: pointer;
        top: 1px;
        left: -7px;
      }
    }
  }
  .part1 {
    margin-top: 50px;
    .part1-content {
      margin-top: 30px;
      background: #f5f7fd;
      padding: 30px;
      .top {
        display: flex;
        align-items: center;
      }
      .mag-img {
        width: 115px;
        height: 76px;
      }
      .arrow-right {
        width: 54px;
        height: 19px;
        margin: 0 20px;
      }
      .content1,
      .content2,
      .content3 {
        width: 19%;
        text-align: center;
      }
    }
    .numBox {
      text-align: center;
      color: #666;
      font-size: 14px;
      margin-top: 20px;
      .dot {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 4px;
      }
      span {
        color: #4054af;
      }
    }
    .numBox3 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      p {
        width: 40%;
        text-align: left;
      }
    }
    .numBox4 {
      margin-left: 30px;
      p {
        text-align: left;
        width: 200px;
      }
    }
  }
}
</style>
